import './style.css';
import { BiCloudUpload } from 'react-icons/bi';

interface DetailsProps {
  heading?: string;
  styles?: string;
  type: string;
  value?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  imageUrl?: string;
  uploadBox?: string;
  placeholder?: string | null;
  uploadButtonStyle?: string;
  title?: string;
  parentStyle?: string | null;
  docTypes?: string;
}

const EditableFiles: React.FC<DetailsProps> = ({
  heading,
  styles,
  onChange,
  uploadBox,
  placeholder,
  name,
  uploadButtonStyle,
  title,
  parentStyle,
  docTypes,
}) => {
  // const docTypes = 'application/pdf';
  return (
    <div className={`flex-col max-w-full alignment ${parentStyle}`}>
      <div className={`w-3/12 p-1  text-left ${styles} editableField`}>
        <p className='text-md flex justify-center headingText'>{heading}</p>
        <label className={`flex items-baseline justify-center ${uploadBox}`}>
          <div className={`alignment  bg-darkGreen w-24 px-2 py-1 rounded-lg text-white ${uploadButtonStyle}`}>
            {title === 'Change Document' ? null : <BiCloudUpload size={20} className='mr-1' />}
            <span className='font-extrabold cursor-pointer'>{title}</span>
            <input
              type='file'
              onChange={onChange}
              accept={`image/png, image/jpeg, ${docTypes}`}
              className='w-full text-sm mt-4'
              name={name}
            />
          </div>
        </label>
      </div>
      <div className='text-sm text-center'> {placeholder}</div>
    </div>
  );
};

export default EditableFiles;
