import { useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Investors_COLUMNS } from '../../../Constant/Column/column';

import { Link } from 'react-router-dom';
import { Listbox } from '@headlessui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const InvestorsTable = ({ investors }: any) => {
  const columns = useMemo<any>(() => Investors_COLUMNS, []);
  const [checkButton, setcheckButton] = useState('');

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: investors,
    },
    useSortBy,
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                  <div className='flex items-center justify-center mx-auto'>
                    <span>{column.render('Header')}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )}
                  </div>
                </th>
              ))}
              <th>View</th>
            </tr>
          ))}
        </thead>
        {investors.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td className='text-center w-4'>
                      <Listbox value={checkButton} onChange={setcheckButton}>
                        <Listbox.Button className='text-center mx-auto'>
                          <Link to='/UserDetailsInvestors' state={JSON.stringify(row.original)}>
                            <div className='mx-2'>
                              <MdRemoveRedEye />
                            </div>
                          </Link>
                        </Listbox.Button>
                      </Listbox>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-1/3 '>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default InvestorsTable;
