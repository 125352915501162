import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Portfolio_History_COLUMN } from '../../../Constant/Column/column';

const PortfolioHistoryTable = ({ editHistory }: any) => {
  const columns = useMemo<any>(() => Portfolio_History_COLUMN, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: editHistory,
  });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, colKey) => (
              <th {...column.getHeaderProps()} key={colKey}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {editHistory?.length ? (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={index}>
                  {row.cells
                    .filter(item => item.value)
                    .map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                </tr>
              </>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <th className='relative left-1/3 '>No matching records found</th>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default PortfolioHistoryTable;
