import dayjs from 'dayjs';
import EditableInput from '../../../components/InputFields/EditableInput';
import EditableSelectInput from '../../../components/InputFields/EditableSelectInput';
import { ACCREDITATION_STATUS, Investor_Type, isIRA, MARRITAL_STATUS } from '../../../Constant/IRAPanelData';
import { EditUserDetailsProps } from '../../../Constant/Types/types';
interface EditPersonalInfoProps {
  userApplication: EditUserDetailsProps;
  handleChange: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  investor_type: string;
  errors: any;
}
const EditPersonalInfo = ({ userApplication, handleChange, errors, investor_type }: EditPersonalInfoProps) => {
  return (
    <div>
      <div className='alignmentBetween mx-4 py-4 px-2'>
        <h2 className='text-lg font-medium mt-8 heading'>PERSONAL INFO</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <div className='grid  mx-6 grid-cols-3'>
          <EditableInput
            heading='First name'
            type='text'
            value={userApplication.first_name}
            onChange={handleChange}
            className='inputStyle'
            name='first_name'
            error={errors.first_name}
          />
          <EditableInput
            heading='Last name'
            type='text'
            value={userApplication.last_name}
            onChange={handleChange}
            className='inputStyle'
            name='last_name'
            error={errors.last_name}
          />
          {isIRA(investor_type) || investor_type === Investor_Type.US_USER ? (
            <EditableInput
              heading='Date Of Birth'
              type='date'
              value={dayjs(userApplication.dob).format('YYYY-MM-DD')}
              onChange={handleChange}
              className='inputStyle'
              name='dob'
              error={errors.dob}
            />
          ) : null}
          {investor_type === Investor_Type.US_ENTITY ? (
            <EditableInput
              heading='EIN'
              type='number'
              value={userApplication.ein}
              onChange={handleChange}
              className='inputStyle'
              error={errors.ein}
              name='ein'
            />
          ) : null}
          {isIRA(investor_type) || investor_type === Investor_Type.US_USER ? (
            <EditableInput
              heading='SSN'
              type='number'
              value={userApplication.ssn}
              onChange={handleChange}
              className='inputStyle'
              error={errors.ssn}
              name='ssn'
            />
          ) : null}
          {investor_type === Investor_Type.US_ENTITY || investor_type === Investor_Type.NON_US_ENTITY ? (
            <EditableInput
              heading='Company Name'
              type='text'
              value={userApplication.company_name}
              onChange={handleChange}
              className='inputStyle'
              error={errors.company_name}
              name='company_name'
            />
          ) : null}
          {isIRA(investor_type) ? (
            <>
              <EditableSelectInput
                captionKey='id'
                handler={handleChange}
                className='inputStyle'
                items={MARRITAL_STATUS}
                selected={userApplication.marrital_status}
                heading={'Marital Status'}
                styles={''}
                name='marrital_status'
              />
              <EditableSelectInput
                captionKey='id'
                handler={handleChange}
                className='inputStyle'
                items={ACCREDITATION_STATUS}
                selected={userApplication.accreditation_type}
                heading={'accreditation status'}
                styles={''}
                name='accreditation_type'
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInfo;
