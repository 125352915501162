import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../../Api/panelApis';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import PdfImage from '../../../components/DocumentImage/pdfImage';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
// import EditableSelectInput from '../../../components/InputFields/EditableSelectInput';
import EditableFiles from '../../../components/InputFields/EditFiles';
import { PDF_MESSAGE } from '../../../Constant/IRAPanelData';
import { toastError, toastSuccess } from '../../../Utils/helper';
const EditAddressDocs = ({ values, documentFileType, setdocumentFileType, userId, convertBase64 }: any) => {
  const [documentFileTypeURL, setdocumentFileTypeURL] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const navigate = useNavigate();

  const submitAddressDoc = async () => {
    setloading(true);
    const data = {
      document_type: values.AddressDocumentType,
      docs_proof: documentFileType,
    };
    try {
      const response = await IRA_ADMIN.noteDocs(data, userId);
      if (response.success) {
        toastSuccess(response.message);
        setloading(false);

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.error);
    }
  };

  const fileName = values?.AddressDocument;
  const fileExtension = fileName?.split('.').pop();
  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-lg font-medium mt-8 heading'>NOTES</h2>
      </div>
      {/* <div className='mx-4 pb-4 px-3'>
        <h2 className='text-sm text-blue-600 font-medium mt-4 heading'>ADDRESS VERIFICATION DOCUMENT</h2>
      </div> */}
      <hr className='hrLine' />

      {/* <div className='mt-6 px-6'>
        <div className='alignmentAround'>
          <EditableSelectInput
            captionKey='id'
            handler={editUserDetails}
            className='inputStyle'
            items={STATEMENT_DOCUMENT_TYPE}
            selected={values.AddressDocumentType}
            title={'Select Document Type'}
            heading={'Document Type:'}
            name='AddressDocumentType'
          />
        </div>
      </div> */}
      <div className='mt-6 px-6'>
        <div className='alignmentAround flex-wrap'>
          <div className='flex-col alignment'>
            {documentFileTypeURL ? (
              documentFileType?.type === 'application/pdf' ? (
                <>
                  <p>DOCUMENT IMAGE</p>
                  <p className='mb-6'>{PDF_MESSAGE}</p>
                  <PdfImage title={documentFileType?.name} />
                </>
              ) : (
                <>
                  {documentFileType ? (
                    <>
                      <p>DOCUMENT IMAGE</p>
                      <p>{PDF_MESSAGE}</p>
                      <DocumentImage src={documentFileTypeURL} />
                    </>
                  ) : (
                    <EditableFiles
                      type='file'
                      imageUrl={documentFileType}
                      onChange={(e: any) => {
                        convertBase64(e.target.files[0], setdocumentFileTypeURL), setdocumentFileType(e.target.files[0]);
                      }}
                      name='documentFile'
                      placeholder={PDF_MESSAGE}
                      title={'Upload'}
                      parentStyle={'bg-grey h-52 p-5 rounded-lg'}
                      uploadButtonStyle={'bg-pink'}
                      docTypes={'application/pdf'}
                    />
                  )}
                </>
              )
            ) : values.AddressDocument ? (
              <>
                <p>DOCUMENT IMAGE</p>
                <p className='mb-3'>{PDF_MESSAGE}</p>
                {fileExtension === 'pdf' ? <PdfPreview url={values.AddressDocument} /> : <DocumentImage src={values.AddressDocument} />}
              </>
            ) : null}
            <>
              <EditableFiles
                type='file'
                imageUrl={documentFileType}
                onChange={(e: any) => {
                  convertBase64(e.target.files[0], setdocumentFileTypeURL), setdocumentFileType(e.target.files[0]);
                }}
                name='documentFile'
                placeholder={documentFileTypeURL || values.AddressDocument ? null : '(You can upload an image file or a pdf document)'}
                title={documentFileTypeURL || values.AddressDocument ? 'Change Document' : 'Upload'}
                parentStyle={documentFileTypeURL || values.AddressDocument ? null : 'bg-grey h-52 p-5 rounded-lg'}
                uploadButtonStyle={documentFileTypeURL || values.AddressDocument ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'}
                docTypes={'application/pdf'}
              />
            </>
          </div>
        </div>
        <div className='flex justify-center mt-5'>
          <button
            onClick={submitAddressDoc}
            className='loadingBtn w-40 m-1'
            disabled={!documentFileTypeURL || !documentFileType || loading}
          >
            {loading ? <ThreeDots height={20} width={30} color='white' ariaLabel='loading-indicator' /> : 'Update Document'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAddressDocs;
