import { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import Button from '../../../../components/Buttons/button';
import HeaderBar from '../../../../components/headerBar/headerBar';
import Modal from '../../../../components/Modal/modal';
import SupportRequestsTable from '../../Table/SupportRequestsTable';

const SupportRequests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [supportRequests, setSupportRequests] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    getSupportRequests();
    return () => {
      controller.abort();
    };
  }, [isModalOpen]);

  const getSupportRequests = useCallback(async () => {
    setIsLoading(true);
    const response = await IRA_ADMIN.getSupportRequests();
    setSupportRequests(response?.reverse());
    setIsLoading(false);
  }, []);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = supportRequests?.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(supportRequests?.length / usersPerPage);
  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const data =
    searchData === ''
      ? displayUsers
      : supportRequests?.filter((user: any) => {
          return (
            JSON.stringify(user.id).includes(searchData) ||
            user?.status?.toLowerCase().includes(searchData) ||
            user?.email.toLowerCase().includes(searchData.toLowerCase()) ||
            user?.subject.toLowerCase().includes(searchData.toLowerCase())
          );
        });

  const onClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className='panelTableContainer'>
      <HeaderBar title={`Support Requests`} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='searchLeadUser'>
              <input type='text' placeholder='Search' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
            <Button
              text='Create New Requests'
              style='w-48 bg-darkblue border-2 border-transparent m-4 mx-auto rounded-md px-4'
              onClick={onClick}
            />
          </div>
          {isLoading ? (
            <div className='alignment h-full w- ' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <SupportRequestsTable support_request={data} />
          )}
          {displayUsers.length ? (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          ) : null}
          <Modal style='max-w-xl' isModalOpen={isModalOpen} setisModalOpen={setIsModalOpen} createSupportRequest={true} />
        </div>
      </div>
    </div>
  );
};

export default SupportRequests;
