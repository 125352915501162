import React from 'react';

const LargeValue: React.FC<{ value: string }> = ({ value }) => {
  return (
    <div className='w-32 mx-auto h-6 overflow-hidden'>
      <span>{value}...</span>
    </div>
  );
};

export default LargeValue;
