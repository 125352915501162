import { Listbox } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import Modal from '../../../components/Modal/modal';
import { Leads_COLUMNS } from '../../../Constant/Column/column';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const LeadsTable = ({ leadsUsers }: any) => {
  const [checkButton, setcheckButton] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificUser, setSpecificUser] = useState([]);
  const columns = useMemo<any>(() => Leads_COLUMNS, []);

  const handleCick = (cell: any) => {
    setSpecificUser(cell);
    setIsModalOpen(true);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: leadsUsers,
    },
    useSortBy,
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                  <div className='flex items-center justify-center mx-auto'>
                    <span>{column.render('Header')}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )}
                  </div>
                </th>
              ))}
              <th>View</th>
            </tr>
          ))}
        </thead>
        {leadsUsers.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells
                      .filter(item => item.value)
                      .map((cell: any, colKey) => {
                        return (
                          <td {...cell.getCellProps()} key={colKey}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    <td className='text-center'>
                      <Listbox value={checkButton} onChange={setcheckButton}>
                        <Listbox.Button className='text-center mx-auto'>
                          <Link
                            to=''
                            onClick={() => {
                              handleCick(row.cells);
                            }}
                          >
                            <MdRemoveRedEye />
                          </Link>
                        </Listbox.Button>
                      </Listbox>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-1/3 '>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
      <Modal style='max-w-5xl' leadsUsers={true} setisModalOpen={setIsModalOpen} isModalOpen={isModalOpen} leadUserData={specificUser} />
    </>
  );
};

export default LeadsTable;
