import React from 'react';
import { IRA_ADMIN } from '../../../Api/panelApis';
import Button from '../../../components/Buttons/button';
import Details from '../../../components/Details/details';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import { UserDetailsProps } from '../../../Constant/Types/types';
import { AppContext } from '../../../Context/Context';
import { toastError, toastSuccess } from '../../../Utils/helper';

interface OtherDocumentsProps {
  document_name: string;
  document_url: string;
}

interface DeleteOtherDocumentProps {
  userDetails: UserDetailsProps;
  userId: string;
}

const DeleteOtherDocument = ({ userDetails, userId }: DeleteOtherDocumentProps) => {
  const { infoUpdated, setInfoUpdated } = React.useContext(AppContext);

  const deleteOtherDocs = async (documentname: string) => {
    try {
      const response = await IRA_ADMIN.deleteOtherDocument(documentname, userId);
      if (response.success) {
        toastSuccess(response.Message);
        setInfoUpdated(!infoUpdated);
      }
    } catch (error: any) {
      toastError(error.response.data.error);
    }
  };
  return (
    <>
      <div className='alignmentAround m-4 flex-wrap'>
        {userDetails?.other_documents.map((document: OtherDocumentsProps, index: number) => {
          const fileName = document.document_url;
          const fileExtension = fileName.split('.').pop();
          return (
            <div key={index} className='alignment flex-col text-center'>
              <Details heading='Document Name' detail={document.document_name} />
              {fileExtension === 'pdf' ? <PdfPreview url={document.document_url} /> : <DocumentImage src={document.document_url} />}
              <Button
                text='Delete'
                style='bg-red w-20 text-white border-2 border-transparent py-1 hover:bg-red mt-4 hover:opacity-70 focus:ring-red '
                onClick={() => deleteOtherDocs(document.document_name)}
              />
            </div>
          );
        })}
      </div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-sm text-blue-600 font-medium mt-6 heading'>UPLOAD OTHER DOCUMENTS</h2>
      </div>
      <hr className='hrLine' />
    </>
  );
};

export default DeleteOtherDocument;
