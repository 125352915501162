interface DocumentImageProps {
  src: any;
}
const DocumentImage: React.FC<DocumentImageProps> = ({ src }) => {
  return (
    <div>
      <a href={src} target='_blank' rel='noreferrer'>
        <img src={src} alt='image' className='mt-8 imageContainer' />
      </a>
    </div>
  );
};

export default DocumentImage;
