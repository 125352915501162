import React, { useState } from 'react';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import FormInput from '../../../components/InputFields/FormInput';
import Button from '../../../components/Buttons/button';
import { IRA_ADMIN } from '../../../Api/panelApis';
import { toastError, toastSuccess } from '../../../Utils/helper';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import Modal from '../../../components/Modal/modal';
import { FaUpload } from 'react-icons/fa'; // Add an icon for customization

const Notes = ({ notes, userId, setInfoUpdated, infoUpdated }: any) => {
  const [notesMsg, setNotesMsg] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editedValue, setEditedValue] = useState('');
  const [toggleModal, setToggleModal] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [editedFile, setEditedFile] = useState<File | null>(null);

  const handleEdit = (index: number) => {
    setEditIndex(index);
    const previousValue = notes[index].description;
    setEditedValue(previousValue);
  };

  const handleDelete = async (id: number) => {
    setToggleModal(false);
    const body = {
      note_id: id,
    };
    const response = await IRA_ADMIN.deleteNotes(body, userId);
    if (response.success) {
      toastSuccess('Notes Deleted Successfully');
      setInfoUpdated(!infoUpdated);
    } else {
      toastError('Something went wrong');
    }
    setToggleModal(false);
  };

  const updateNotes = async (id: number) => {
    const body = {
      user_id: userId,
      note_id: id,
      description: editedValue,
      document: editedFile,
    };
    const response = await IRA_ADMIN.updateNotes(body);
    if (response.id) {
      toastSuccess('Notes Updated Successfully');
      setInfoUpdated(!infoUpdated);
      setEditIndex(-1);
      setEditedValue('');
      setEditedFile(null);
    } else {
      toastError('Something went wrong');
    }
  };

  const handleCancel = () => {
    setEditIndex(-1);
    setEditedValue('');
    setEditedFile(null);
  };

  const createNotes = async () => {
    const body = {
      user_id: userId,
      description: notesMsg,
      document: file,
    };
    const response = await IRA_ADMIN.createNotes(body);
    if (response.id) {
      toastSuccess('Notes Added Successfully');
      setInfoUpdated(!infoUpdated);
      setNotesMsg('');
      setFile(null);
    } else {
      toastError('Something went wrong');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === 'application/pdf' || selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
      } else {
        toastError('Only PDF and image files are allowed');
      }
    }
  };
  const handleEditFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === 'application/pdf' || selectedFile.type.startsWith('image/')) {
        setEditedFile(selectedFile);
      } else {
        toastError('Only PDF and image files are allowed');
      }
    }
  };

  return (
    <CollapsibleContainer title='NOTES'>
      <div className='mt-6 px-6'>
        <div className='w-4/5 mx-auto m-4'>
          <textarea
            placeholder='Additional Notes'
            name='notes'
            value={notesMsg}
            onChange={(e: any) => setNotesMsg(e.target.value)}
            className='focus:border-darkblue focus:outline-none border-2 border-darkblue px-3 py-2 text-darkblue placeholder:text-sm placeholder-gray-400 appearance-none w-full'
            rows={3}
          />
          {notesMsg.length > 256 && (
            <p className='text-center text-xs text-red font-extrabold'>Notes should not be greater than 256 characters*</p>
          )}

          <div className='relative w-full mt-4'>
            <input type='file' id='fileInput' accept='application/pdf,image/*' onChange={handleFileChange} className='hidden' />
            <label
              htmlFor='fileInput'
              className='flex items-center justify-center border-2 border-darkblue px-3 py-2 text-darkblue placeholder:text-sm placeholder-gray-400 appearance-none w-full cursor-pointer'
            >
              {file ? file.name : 'Upload File (PDF or Image)'}
              <FaUpload className='ml-2' />
            </label>
          </div>

          <Button
            text='Add Notes'
            style='bg-darkblue w-40 mx-auto border-2 border-transparent m-4 rounded-md px-4'
            onClick={createNotes}
            disabled={!notesMsg || notesMsg.length > 256}
          />
        </div>
        <ul className='mx-6 list-disc'>
          {notes?.length
            ? notes.reverse().map((val: any, index: number) => {
                const isEditable = index === editIndex;
                const fileInputId = `fileInput-${index}`; // Unique ID for each file input

                return (
                  <div key={val.id} className='flex items-start py-1 justify-between'>
                    {isEditable ? (
                      <div className='flex w-full flex-col'>
                        <FormInput
                          type='text'
                          name='editNotes'
                          value={editedValue}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditedValue(e.target.value)}
                          style='w-11/12 border-2'
                        />
                        {editedValue.length > 256 && (
                          <p className='text-center mt-2 text-xs text-red font-extrabold'>
                            Notes should not be greater than 256 characters*
                          </p>
                        )}
                        <div className='relative w-11/12 mt-4'>
                          <input
                            type='file'
                            id={fileInputId} // Unique ID for each file input
                            accept='application/pdf,image/*'
                            onChange={e => handleEditFileChange(e)}
                            className='hidden'
                          />
                          <label
                            htmlFor={fileInputId} // Associate label with unique ID
                            className='flex items-center justify-center border-2 border-darkblue px-3 py-2 text-darkblue placeholder:text-sm placeholder-gray-400 appearance-none w-full cursor-pointer'
                          >
                            {editedFile ? editedFile.name : 'Upload File (PDF or Image)'}
                            <FaUpload className='ml-2' />
                          </label>
                        </div>
                        <div className='flex my-3 gap-x-2 mx-auto'>
                          <Button disabled={!editedValue || editedValue.length > 256} text='Save' onClick={() => updateNotes(val.id)} />
                          <Button text='Cancel' onClick={handleCancel} />
                        </div>
                      </div>
                    ) : (
                      <li className='w-11/12'>{val.description}</li>
                    )}
                    <div className='flex gap-x-2 mt-1'>
                      {!isEditable && (
                        <MdDelete
                          className='cursor-pointer'
                          size={20}
                          onClick={() => {
                            setToggleModal(true);
                            setDeleteNoteId(val.id);
                          }}
                        />
                      )}
                      {!isEditable && <BiEdit className='cursor-pointer' size={20} onClick={() => handleEdit(index)} />}
                    </div>
                  </div>
                );
              })
            : 'Notes not available'}
        </ul>
      </div>
      <Modal
        deleteNotes={true}
        deleteHandler={() => handleDelete(deleteNoteId)}
        setisModalOpen={setToggleModal}
        isModalOpen={toggleModal}
      />
    </CollapsibleContainer>
  );
};

export default Notes;
