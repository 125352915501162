// import { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { MdBackupTable } from 'react-icons/md';
// import { FiChevronDown } from 'react-icons/fi';
import { AiFillDashboard, AiFillSetting } from 'react-icons/ai';
import { FaUsers, FaMoneyCheckAlt } from 'react-icons/fa';
import './sideBar.css';

const SideBar = () => {
  // const [toggleApplication, setToggleApplication] = useState(false);
  // const [toggleTransaction, setToggleTransaction] = useState(false);

  // const closeAll = () => {
  //   setToggleApplication(false);
  //   // setToggleTransaction(false);
  // };

  return (
    <div className='panelSidebar'>
      <div className='logo'>
        <img src='/Assets/carl-logo.png' alt='carl-logo' />
        <p className='text-white mx-12 text-sm'> Admin Panel</p>
      </div>
      <div className='categoriesContainer'>
        <aside className='w-64' aria-label='Sidebar'>
          <div className=' py-4 px-3 rounded bg-#091b41'>
            <ul className='space-y-2'>
              <li>
                <NavLink
                  to='/dashboard'
                  className={({ isActive }) =>
                    `${isActive ? 'isActive' : 'not-active'} flex items-center  p-2 fa-10x text-xl font-normal  rounded-lg`
                  }
                >
                  <AiFillDashboard />
                  <span className='ml-3 '>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/investors'
                  className={({ isActive }) =>
                    `${isActive ? 'isActive' : 'not-active'} flex items-center  p-2 fa-10x text-xl font-normal  rounded-lg`
                  }
                >
                  <FaMoneyCheckAlt />
                  <span className='ml-3 '>Verified Investors</span>
                </NavLink>
              </li>
              {/* <li className='group'>
                <button
                  type='button'
                  className='flex items-center p-2 w-full text-xl font-normal rounded-lg transition duration-75 group text-white'
                  aria-controls='dropdown-example'
                  data-collapse-toggle='dropdown-example'
                  onClick={() => {
                    closeAll();
                    setToggleApplication(!toggleApplication);
                  }}
                >
                  <FaUsers />
                  <span className='flex-1 ml-3 text-left whitespace-nowrap' sidebar-toggle-item=''>
                    KYC Applications
                  </span>
                  <FiChevronDown size={24} />
                </button>
                <ul id='dropdown-example' className={`${!toggleApplication && 'hidden'}  py-2 space-y-2`}>
                  <li>
                    <NavLink to='/ira' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer `}>
                      <h4 className='text-center font-bold sidebarHeading'>IRA </h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/us_user' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>US Individual</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/us_entity' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>US Entity</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/non_us_user' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>Non US Individual</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/non_us_entity' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>Non US Entity</h4>
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              <li className='group'>
                {/* <button
                  type='button'
                  className='flex items-center p-2 w-full text-xl font-normal rounded-lg transition duration-75 group text-white'
                  aria-controls='dropdown-example'
                  data-collapse-toggle='dropdown-example'
                  onClick={() => {
                    closeAll();
                    setToggleApplication(!toggleApplication);
                  }}
                > */}
                {/* <NavLink to='/us_user' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                  <FaUsers />
                  <span className='flex-1 ml-3 text-left whitespace-nowrap' sidebar-toggle-item=''>
                    Applications
                  </span>
                </NavLink> */}
                <NavLink
                  to='/us_user'
                  className={({ isActive }) =>
                    `${isActive ? 'isActive' : 'not-active'} flex items-center  p-2 fa-10x text-xl font-normal  rounded-lg`
                  }
                >
                  <FaUsers />
                  <span className='ml-3 '>Applications</span>
                </NavLink>
                {/* <FiChevronDown size={24} /> */}
                {/* </button> */}
              </li>
              {/* <li className='group'>
                <button
                  type='button'
                  className='flex items-center p-2 w-full text-xl font-normal rounded-lg transition duration-75 group text-white'
                  aria-controls='dropdown-example'
                  data-collapse-toggle='dropdown-example'
                  onClick={() => {
                    closeAll();
                    setToggleTransaction(!toggleTransaction);
                  }}
                >
                  <AiFillBank />
                  <span className='flex-1 ml-3 text-left whitespace-nowrap' sidebar-toggle-item=''>
                    Transactions
                  </span>

                  <FiChevronDown size={24} />
                </button>
                <ul id='dropdown-example' className={`${!toggleTransaction && 'hidden'} py-2 space-y-2`}>
                  <li>
                    <NavLink to='/transactions/ira' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>IRA</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/transactions/us_user' className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}>
                      <h4 className='text-center font-bold sidebarHeading'>US Individual</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/transactions/us_entity'
                      className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}
                    >
                      <h4 className='text-center font-bold sidebarHeading'>US Entity</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/transactions/non_us_entity'
                      className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}
                    >
                      <h4 className='text-center font-bold sidebarHeading'>Non US Entity</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/transactions/non_us_user'
                      className={({ isActive }) => `${isActive ? 'isActive' : 'not-active'} ulContainer`}
                    >
                      <h4 className='text-center font-bold sidebarHeading'>Non US Individual</h4>
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              {/* <li>
                <NavLink
                  to='/leads'
                  className={({ isActive }) =>
                    `${isActive ? 'isActive' : 'not-active'} flex items-center  p-2 fa-10x text-xl font-normal  rounded-lg`
                  }
                >
                  <MdBackupTable />
                  <span className='ml-3 '>Leads Users</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to='/support_requests'
                  className={({ isActive }) =>
                    `${isActive ? 'isActive' : 'not-active'} flex items-center  p-2 fa-10x text-xl font-normal  rounded-lg`
                  }
                >
                  <AiFillSetting />
                  <span className='ml-3 '>Support Requests</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SideBar;
