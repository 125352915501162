const PdfPreview = ({ url }: any) => {
  return (
    <>
      <a href={url} target='_blank' rel='noreferrer'>
        <div className='alignmentStart'>
          <img width='100' height='100' src='/Assets/pdflogo.png' alt='pdf-logo' />
        </div>
      </a>
    </>
  );
};

export default PdfPreview;
