import { useLocation } from 'react-router-dom';
import HeaderBar from '../../components/headerBar/headerBar';
import TransactionSection from './Section/transactionSection';

interface CustomizedState {
  id: string;
}

const AddFunds = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { id } = state;

  return (
    <div className='w-full max-w-8xl'>
      <HeaderBar title={`Funds Transactions`} />
      <div className='inline-block w-full max-w-8xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform rounded-2xl '>
        <div className='w-full max-w-8xl p-6 my-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2x2'>
          <TransactionSection userId={id} />
        </div>
      </div>
    </div>
  );
};

export default AddFunds;
