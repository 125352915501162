// eslint-disable-next-line no-useless-escape
export const ACCOUNT_TYPE = [
  {
    id: 1,
    name: 'CARL IRA',
  },
];
export const COUNTRY = [
  {
    id: 1,
    name: 'US',
  },
  {
    id: 2,
    name: 'Non-US',
  },
];
export const CHECK_BUTTON = [{ id: 1, name: 'View ', icon: 'MdModeEditOutline' }];

export const ADMIN_SELECT = [
  { name: 'Edit User', icon: 'MdModeEditOutline', route: 'ChangePassword' },
  { name: 'Logout', route: 'ira' },
];
export const CARL_TYPES = ['CARL IRA (Roth)', 'CARL IRA (Traditional)'];

export const CARL_TYPE = [
  {
    id: false,
    name: 'CARL IRA (Roth)',
  },
  {
    id: true,
    name: 'CARL IRA (Traditional)',
  },
];
export const TRANSFER_ACCOUNT_TYPES = [
  'From another IRA',
  'From a 401k I had at a company I left (Rollover)',
  'From a regular IRA to a Roth IRA (Conversion)',
];
export const TRANSFER_ACCOUNT = [
  {
    id: 1,
    name: 'From another IRA',
  },
  {
    id: 2,
    name: 'From a 401k I had at a company I left (Rollover)',
  },
  {
    id: 3,
    name: 'From a regular IRA to a Roth IRA (Conversion)',
  },
];
export const APPLICATION_STATUS = [
  {
    id: 0,
    name: 'All',
    value: 'ALL',
  },
  {
    id: 1,
    name: 'Approved',
    value: 'APPROVED',
  },
  {
    id: 2,
    name: 'Pending',
    value: 'IN PROCESS',
  },
  {
    id: 3,
    name: 'Incomplete',
    value: 'ONBOARDING PENDING',
  },
  {
    id: 4,
    name: 'Resubmit',
    value: 'RESUBMIT',
  },
  {
    id: 5,
    name: 'Rejected',
    value: 'DECLINED',
  },
  {
    id: 6,
    name: 'Dwolla Pending',
    value: 'BANK PENDING',
  },
];

export const APPLICATION_DURATION = [
  {
    id: 0,
    name: 'All Users',
    value: 'ALL',
  },
  {
    id: 1,
    name: '30 Days',
    value: 'user_application_thirty_day',
  },
  {
    id: 2,
    name: '90 Days',
    value: 'user_application_ninety_day',
  },
];

export const SEARCH_TYPES = [
  {
    id: 1,
    name: 'User ID',
    value: 'user_id',
  },
  {
    id: 2,
    name: 'Name',
    value: 'first_name',
  },
  {
    id: 3,
    name: 'Email',
    value: 'email',
  },
  {
    id: 4,
    name: 'Phone Number',
    value: 'phone_number',
  },
];

export const STATUS = {
  NOT_STARTED: 'NOT STARTED',
  ONBOARDING_PENDING: 'ONBOARDING PENDING',
  PENDING: 'IN PROCESS',
  RESUBMIT: 'RESUBMIT',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  BANK_PENDING: 'BANK PENDING',
  BANK_APPROVED: 'BANK APPROVED',
};

export const Transactions_STATUS = {
  STATUS_ACTIVE: 1,
  STATUS_EXPIRED: 0,
  STATUS_CANCELLED: 2,
  STATUS_PROCESSED: 3,
};

export const Transactions_TYPE = {
  WITHDRAW: 0,
  DEPOSIT: 1,
};

export const Investor_Type = {
  US_USER: 'US USER',
  NON_US_USER: 'NON US USER',
  US_ENTITY: 'US ENTITY',
  NON_US_ENTITY: 'NON US ENTITY',
  IRA_USER: 'IRA USER',
};

export const isIRA = (investor_type: string) => {
  switch (investor_type) {
    case Investor_Type.NON_US_ENTITY:
    case Investor_Type.NON_US_USER:
    case Investor_Type.US_ENTITY:
    case Investor_Type.US_USER:
      return false;
    default:
      return true;
  }
};

export const DOCUMENT_TYPES = ['Passport', 'Driving Licence', 'Identity Card'];
export const DOCUMENT_TYPE = [
  {
    id: 1,
    name: 'Passport',
  },
  {
    id: 2,
    name: 'Driving Licence',
  },
  {
    id: 3,
    name: 'Identity Card',
  },
];
export const STATEMENT_TYPE = ['', 'Utility Bill', 'Bank Statement'];
export const STATEMENT_DOCUMENT_TYPE = [
  {
    id: 1,
    name: 'Utility Bill',
  },
  {
    id: 2,
    name: 'Bank Statement',
  },
];

export const TRANSACTION_TYPE = [
  {
    id: 1,
    name: 'Deposit',
    value: 'deposit',
  },
  {
    id: 2,
    name: 'Withdraw',
    value: 'withdraw',
  },
];

export const MARRITAL_STATUS = [
  {
    id: true,
    name: 'Married',
  },
  {
    id: false,
    name: 'Unmarried',
  },
];
export const ACCREDITATION_STATUS_SELECT = [
  'I earn more than $200K per year or, jointly with my spouse, more than $300K per year.',
  'I have more than $1M in assets, not including my primary residence.',
  'I invest on behalf of an entity or tryst with more than $5M in assets or an entity which all owners are accredited investors',
  'None',
];
export const ACCREDITATION_STATUS = [
  {
    id: 0,
    name: 'I earn more than $200K per year or, jointly with my spouse, more than $300K per year.',
  },
  {
    id: 1,
    name: 'I have more than $1M in assets, not including my primary residence.',
  },
  {
    id: 2,
    name: 'I invest on behalf of an entity or tryst with more than $5M in assets or an entity which all owners are accredited investors',
  },
  {
    id: 3,
    name: 'None',
  },
];
export const DURATIONS = [
  {
    id: 3,
    name: '3 Months',
  },
  {
    id: 6,
    name: '6 Months',
  },
  {
    id: 9,
    name: '9 Months',
  },
  {
    id: 12,
    name: '12 Months',
  },
];

export const EMAIL_CONTENT = `
We have reviewed what you sent and in order to proceed with our process, we need more information from you.
Please reply to this email at your earliest convenience and include a high resolution photograph of your passport
making sure that your information is in focus and clearly legible.
Alternatively, you can click here to upload items to the CARL App.
Thank you,
Team @ CARL`;

export const PDF_MESSAGE = '(You can upload an image file or a pdf document)';
export const INFORM_USER_MESSAGE = 'Send your message in the form below to inform user about their KYC.';

export const exceptThisSymbols = ['e', 'E', '-', '.'];
