import { useCallback, useEffect, useState } from 'react';
import { MdPending } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import HeaderBar from '../../../../components/headerBar/headerBar';
import HeaderCards from '../../../../components/HeaderCards/headerCards';
import InvestedStrategiesTable from '../../Table/InvestedStrategiesTable';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<any>([]);

  const getDashboardDetails = useCallback(async () => {
    const response = await IRA_ADMIN.dashboard();
    setDashboardData(response);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getDashboardDetails();
  }, []);
  return (
    <div className='panelTableContainer'>
      <HeaderBar title={`Dashboard`} />
      <div className='alignmentAround bg-[#F2F4FF] flex-wrap'>
        <HeaderCards
          title={'Total investor count'}
          value={dashboardData?.total_investor_count}
          iconBackground='rgb(205, 229, 253)'
          icon={<MdPending color='rgb(35, 116, 200)' size={24} />}
        />
        <HeaderCards
          title={'Last week approved user'}
          value={dashboardData?.approved_users_in_last_week}
          iconBackground='rgb(205, 229, 253)'
          icon={<MdPending color='rgb(35, 116, 200)' size={24} />}
        />
        <HeaderCards
          title={'Last week unapproved user'}
          value={dashboardData?.unapproved_users_in_last_week}
          iconBackground='rgb(205, 229, 253)'
          icon={<MdPending color='rgb(35, 116, 200)' size={24} />}
        />
      </div>
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <p className='text-2xl font-bold mx-auto'>Strategies Investment</p>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <InvestedStrategiesTable strategies={dashboardData.strategy_investments} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
