import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { MdOutlineClose, MdErrorOutline } from 'react-icons/md';
import { IRA_ADMIN } from '../../Api/panelApis';
import { EMAIL_CONTENT, INFORM_USER_MESSAGE } from '../../Constant/IRAPanelData';
import ForgotModal from './forgotModal';
import InformUserModal from './informUserModal';
import RejectKYC from './rejectKYC';
import CreateAdmin from './createAdminModal';
import { toastError, toastSuccess } from '../../Utils/helper';
import LeadUserModal from './LeadUserModal';
import CreatePortfolioModal from './CreatePortfolioModal';
import CreateSupportModal from './CreateSupportModal';
import DeletePortfolio from './DeletePortfolio';
import ViewRequest from './ViewRequest';
import RejectTransaction from './rejectTransactionsModal';
import DeleteNotes from './DeleteNotes';
import ViewCertificate from './viewCertificate';
import ApproveKYC from './approveKYC';

interface ModalProps {
  setisModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  name?: string;
  email?: any;
  userId?: string;
  type?: string;
  isInformUser?: boolean;
  rejectHandler?: (value: boolean) => void;
  approveModal?: boolean;
  approveHandler?: (value: boolean) => void;
  createAdmin?: boolean;
  setInfoUpdated?: any;
  InfoUpdated?: boolean;
  leadsUsers?: boolean;
  createPortfolio?: boolean;
  createSupportRequest?: boolean;
  style?: string;
  leadUserData?: object;
  deletePortfolio?: boolean;
  deleteHandler?: any;
  viewRequest?: boolean;
  requestData?: any;
  portfolioDetail?: any;
  isPortfolioEdit?: boolean;
  rejectTransaction?: boolean;
  deleteNotes?: boolean;
  coginitoData?: boolean;
  viewCertificate?: boolean;
  certificateUrl?: string;
}

const Modal: React.FC<ModalProps> = ({
  isModalOpen,
  setisModalOpen,
  name,
  email,
  userId,
  type,
  isInformUser,
  rejectHandler,
  approveModal,
  approveHandler,
  createAdmin,
  setInfoUpdated,
  InfoUpdated,
  leadsUsers,
  createPortfolio,
  createSupportRequest,
  style,
  leadUserData,
  coginitoData,
  deletePortfolio,
  deleteHandler,
  viewRequest,
  requestData,
  portfolioDetail,
  isPortfolioEdit,
  rejectTransaction,
  deleteNotes,
  viewCertificate,
  certificateUrl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const user_id = localStorage.getItem('user_id');

  const informUser = async () => {
    try {
      const res = await IRA_ADMIN.informUserByEmail(email, emailContent, Number(userId));
      if (res.success) {
        setIsOpen(false);
        toastSuccess('Inform User Successful');
        setInfoUpdated(!InfoUpdated);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setisModalOpen(false);
  };

  React.useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const isCustomWidth = createSupportRequest || leadsUsers || createPortfolio || viewCertificate;

  const emailContent = `Dear ${name}, 
   ${EMAIL_CONTENT}`;

  const modalTitle = createAdmin ? (
    <h2 className='text-xl text-darkblue font-extrabold'>Create New Admin</h2>
  ) : type ? (
    <h2 className='text-xl text-darkblue font-extrabold'>Change Password</h2>
  ) : leadsUsers || viewRequest ? (
    ''
  ) : createPortfolio ? (
    <h2 className='text-3xl mx-auto text-center text-darkblue font-extrabold'>{isPortfolioEdit ? 'Edit' : 'Create'} Portfolio</h2>
  ) : createSupportRequest ? (
    <h2 className='text-2xl mx-auto text-center text-darkblue font-extrabold'>Create New Support Request</h2>
  ) : isInformUser ? (
    INFORM_USER_MESSAGE
  ) : viewCertificate ? (
    <h2 className='text-2xl mx-auto text-center text-darkblue font-extrabold'>Investor Certificate</h2>
  ) : (
    <div className='flex-col alignment'>
      <MdErrorOutline size={80} color='#ffcc80' className='mb-4' />
      <h2 className='text-extrabold text-darkblue text-3xl'>Are You Sure?</h2>
    </div>
  );

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-10' onClose={closeModal}>
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-200'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-opacity-50 bg-slate-600' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className={`modalContainer ${style || ''} ${viewCertificate ? 'max-w-[800px]' : 'max-w-lg'} `}>
                <div className='flex justify-end py-2 px-4 items-center'>
                  <button onClick={closeModal}>
                    <MdOutlineClose size={24} className='text-darkblue' />
                  </button>
                </div>
                <div
                  className={`w-full ${
                    isCustomWidth ? '' : 'max-w-lg'
                  } p-2 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2x2`}
                >
                  <div className='mx-4 py-2 px-2 items-center'>
                    <div className='text-center alignment'>
                      <Dialog.Title as='div' className='text-md text-center leading-6 text-darkGrey font-bold'>
                        {modalTitle}
                      </Dialog.Title>
                    </div>
                  </div>
                  {createAdmin ? (
                    <CreateAdmin setIsOpen={setIsOpen} setisModalOpen={setisModalOpen} />
                  ) : type ? (
                    <ForgotModal setIsOpen={setIsOpen} setisModalOpen={setisModalOpen} user_id={user_id} />
                  ) : isInformUser ? (
                    <InformUserModal name={name} email={email} informUser={informUser} emailContent={emailContent} />
                  ) : leadsUsers ? (
                    <LeadUserModal leadUserData={leadUserData} coginitoData={coginitoData} />
                  ) : createPortfolio ? (
                    <CreatePortfolioModal userId={userId} portfolioDetail={portfolioDetail} />
                  ) : createSupportRequest ? (
                    <CreateSupportModal setisModalOpen={setisModalOpen} />
                  ) : deletePortfolio ? (
                    <DeletePortfolio closeModal={closeModal} deleteHandler={deleteHandler} />
                  ) : viewRequest ? (
                    <ViewRequest requestData={requestData} />
                  ) : rejectTransaction ? (
                    <RejectTransaction closeModal={closeModal} rejectHandler={rejectHandler} />
                  ) : deleteNotes ? (
                    <DeleteNotes closeModal={closeModal} deleteHandler={deleteHandler} />
                  ) : viewCertificate ? (
                    <ViewCertificate certificateUrl={certificateUrl} />
                  ) : approveModal ? (
                    <ApproveKYC closeModal={closeModal} approveHandler={approveHandler} />
                  ) : (
                    <RejectKYC closeModal={closeModal} rejectHandler={rejectHandler} />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
