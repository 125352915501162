import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Investor_Type } from '../Constant/IRAPanelData';

export const IRA_UserSchema = Yup.object({
  first_name: Yup.string().max(15, 'Must be 15 characters or less*').required('First Name is required*'),
  ira_account_type: Yup.string().required('Transfer Account is required*'),
  last_name: Yup.string().max(15, 'Must be 15 characters or less*').required('Last Name is required*'),
  dob: Yup.string()
    .required('DOB is Required')
    .test('DOB', 'User must be 18 years or older*', date => dayjs().diff(dayjs(date), 'years') >= 18),
  ssn: Yup.string().min(9, 'SSN must have 9 digits*').max(9, 'SSN must have 9 digits*').required('SSN is required*'),
  initial_ira_amount: Yup.number().min(20000, 'Minimum Amount is $20000*').required('IRA Amount is required*'),
  address1: Yup.string().max(30, 'Must be 30 characters or less*').required('Address is required*'),
  zipCode: Yup.string().max(10, 'Must be 10 characters or less*').required('ZipCode is required*'),
  state: Yup.string().max(20, 'Must be 20 characters or less*').required('State is required*'),
  city: Yup.string().max(20, 'Must be 20 characters or less*').required('City is required*'),
  country: Yup.string().required('Country is required*'),
  custodian_amount: Yup.number().when(['ira_account_type'], {
    is: (ira_account_type: string) => ira_account_type === '1',
    then: Yup.number().min(20000, 'Minimum Amount is $20000*').required('Custodian Amount is Required*'),
  }),
  custodian_account_number: Yup.string().when(['ira_account_type'], {
    is: (ira_account_type: string) => ira_account_type === '1',
    then: Yup.string().max(20, 'Must be 20 characters or less*').required('Custodian Number is Required*'),
  }),
  custodian_account_name: Yup.string().when(['ira_account_type'], {
    is: (ira_account_type: string) => ira_account_type === '1',
    then: Yup.string().max(20, 'Must be 20 characters or less*').required('Custodian Name is Required*'),
  }),
});

export const UserSchema = (investor_type: string) => {
  if (investor_type === Investor_Type.IRA_USER) {
    return IRA_UserSchema;
  }
  if (
    investor_type === Investor_Type.US_ENTITY ||
    investor_type === Investor_Type.NON_US_ENTITY ||
    investor_type === Investor_Type.NON_US_USER
  ) {
    return ENT_Schema;
  }
  return US_IND_UserSchema;
};
const ENT_Schema = Yup.object({
  first_name: Yup.string().max(15, 'Must be 15 characters or less*').required('First Name is required*'),
  last_name: Yup.string().max(15, 'Must be 15 characters or less*').required('Last Name is required*'),
  company_name: Yup.string().when('investor_type', {
    is: 'US ENTITY' || 'NON US ENTITY',
    then: Yup.string().max(15, 'Must be 15 characters or less*').required('Company Name is required*'),
  }),
  address1: Yup.string().max(30, 'Must be 30 characters or less*').required('Address is required*'),
  zipCode: Yup.string().max(10, 'Must be 10 characters or less*').required('ZipCode is required*'),
  ein: Yup.string().when('investor_type', {
    is: 'US ENTITY',
    then: Yup.string().min(9, 'EIN must have 9 digits*').max(9, 'EIN must have 9 digits*').required('EIN is required*'),
  }),
  investor_type: Yup.string(),
  state: Yup.string().when('investor_type', {
    is: 'US ENTITY',
    then: Yup.string().max(20, 'Must be 20 characters or less*').required('State is required*'),
  }),
  city: Yup.string().max(20, 'Must be 20 characters or less*').required('City is required*'),
  country: Yup.string().required('Country is required*'),
});

export const US_IND_UserSchema = Yup.object({
  first_name: Yup.string().max(15, 'Must be 15 characters or less*').required('First Name is required*'),
  last_name: Yup.string().max(15, 'Must be 15 characters or less*').required('Last Name is required*'),
  dob: Yup.string()
    .required('DOB is Required')
    .test('DOB', 'User must be 18 years or older*', date => dayjs().diff(dayjs(date), 'years') >= 18),
  ssn: Yup.string().min(9, 'SSN must have 9 digits*').max(9, 'SSN must have 9 digits*').required('SSN is required*'),
  address1: Yup.string().max(30, 'Must be 30 characters or less*').required('Address is required*'),
  zipCode: Yup.string().max(10, 'Must be 10 characters or less*').required('ZipCode is required*'),
  state: Yup.string().max(20, 'Must be 20 characters or less*').required('State is required*'),
  city: Yup.string().max(20, 'Must be 20 characters or less*').required('City is required*'),
  country: Yup.string().required('Country is required*'),
});

export const craeteAdminSchema = Yup.object({
  first_name: Yup.string().max(15, 'Must be 15 characters or less*').required('First Name is required*'),
  last_name: Yup.string().max(15, 'Must be 15 characters or less*').required('Last Name is required*'),
  phone_number: Yup.string()
    .min(7, 'Must be 7 Numbers or long')
    .max(15, 'Must be 15 characters or less*')
    .required('Phone Number is required*'),
  email: Yup.string().email('Email is invalid').required('Email is required*'),
  adminPassword: Yup.string().min(6, 'Must be 6 charactor or long').required('Password is required*'),
});

export const transactionSchema = Yup.object({
  amount: Yup.number().min(1, "Amount can't be zero or less than zero").required('Amount is required*'),
  start_date: Yup.string().required('Start Date is Required'),
  end_date: Yup.string().required('End Date is Required'),
});
