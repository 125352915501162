import './style.css';
interface DetailsProps {
  heading?: string;
  styles?: string;
  type?: string;
  value?: string | number | undefined;

  onChange?: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  className?: string;
  name?: string;
  error?: string;
  placeholder?: string;
  icon?: boolean;
  onKeyDown?: any;
}

const EditableInput: React.FC<DetailsProps> = ({
  heading,
  styles,
  type,
  onChange,
  value,
  className,
  name,
  error,
  placeholder,
  icon,
  onKeyDown,
}) => {
  return (
    <div className={`w-3/12 p-1  text-left ${styles} editableField`}>
      <p className='text-md headingText'>{heading}</p>
      <div className='alignment border-b-pink'>
        <span className=' relative left-2'>{icon && '$'}</span>
        <input
          type={type}
          className={`mt-1 text-darkblue border-b-pink font-extrabold px-2 ${className || ''} editableField`}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete='off'
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
      <p className='font-bold ml-1 text-red text-xs'>{error}</p>
    </div>
  );
};

export default EditableInput;
