import { Routes, Route, Navigate } from 'react-router-dom';
import Users from '../screens/IRAPanel/MainSection/Users/Users';
import IRAPanel from '../screens/IRAPanel/MainSection/Users/IRAPanel';
import UserDetails from '../screens/userDetail/userDetails';
import LeadsUsers from '../screens/IRAPanel/MainSection/LeadsUsers/LeadsUsers';
import AddFunds from '../screens/userDetail/AddFunds';
import Transactions from '../screens/IRAPanel/MainSection/FundsTransactions/Transactions';
import '../screens/IRAPanel/MainSection/Users/iraPanel.css';
import SideBar from '../components/SideBar/sideBar';
import TransactionDetail from '../screens/userDetail/TransactionDetail';
import Dashboard from '../screens/IRAPanel/MainSection/Dashboard/Dashboard';
import Investors from '../screens/IRAPanel/MainSection/Investors/Investors';
import SupportRequests from '../screens/IRAPanel/MainSection/SupportRequests/SupportRequests';
import EditDetails from '../screens/EditatableFields/EditDetails';
import EditTransactions from '../screens/EditatableFields/EditTransactions';
import StrategyDetails from '../screens/userDetail/StrategyDetails';

const ProtectedRoutes = () => {
  return (
    <div className='headerContainer'>
      <div className='mainPanelContainer'>
        <SideBar />
        <Routes>
          <Route path='/ira' element={<IRAPanel />} />
          <Route path='/us_user' element={<Users />} />
          <Route path='/non_us_user' element={<Users />} />
          <Route path='/us_entity' element={<Users />} />
          <Route path='/non_us_entity' element={<Users />} />
          <Route path={'/UserDetailsInvestors'} element={<UserDetails />} />
          <Route path={'/UserDetailsApplications'} element={<UserDetails />} />
          <Route path={'/UserDetails'} element={<UserDetails />} />
          <Route path='/editDetails' element={<EditDetails />} />
          <Route path='/leads' element={<LeadsUsers />} />
          <Route path='/support_requests' element={<SupportRequests />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/investors' element={<Investors />} />
          <Route path='/addFunds' element={<AddFunds />} />
          <Route path='/transactions/ira' element={<Transactions />} />
          <Route path='/transactions/us_user' element={<Transactions />} />
          <Route path='/transactions/non_us_entity' element={<Transactions />} />
          <Route path='/transactions/us_entity' element={<Transactions />} />
          <Route path='/transactions/non_us_user' element={<Transactions />} />
          <Route path='/transactionDetail' element={<TransactionDetail />} />
          <Route path='/editTransaction' element={<EditTransactions />} />
          <Route path='/strategy_details' element={<StrategyDetails />} />
          <Route path='*' element={<Navigate to='/dashboard' />} />
        </Routes>
      </div>
    </div>
  );
};
export default ProtectedRoutes;
