/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { Listbox } from '@headlessui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { COLUMNS } from '../../../Constant/Column/column';
import '../IraPanel.css';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const Table = ({ userData }: any) => {
  const [checkButton, setcheckButton] = React.useState('');
  const columns = useMemo<any>(() => COLUMNS, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: userData,
    },
    useSortBy,
  );

  const getRoute = window.location.href;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, colKey) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                <div className='flex items-center justify-center mx-auto'>
                  <span>{column.render('Header')}</span>
                  {column.disableSortBy ? null : column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaSortDown className='ml-1' />
                    ) : (
                      <FaSortUp className='ml-1' />
                    )
                  ) : (
                    <FaSort className='ml-1' />
                  )}
                </div>
              </th>
            ))}
            <th>View</th>
          </tr>
        ))}
      </thead>
      {userData.length ? (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const originalData = JSON.parse(JSON.stringify(row.original));
            originalData.perviousPath = getRoute;
            return (
              <>
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, index) => {
                    return (
                      <td {...cell.getCellProps()} key={index}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  <td className='text-center w-4'>
                    <Listbox value={checkButton} onChange={setcheckButton}>
                      <Listbox.Button className='text-center mx-auto'>
                        <Link to='/UserDetailsApplications' state={JSON.stringify(originalData)}>
                          <div className='mx-2'>
                            <MdRemoveRedEye />
                          </div>
                        </Link>
                      </Listbox.Button>
                    </Listbox>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <th className='relative left-1/3'>No matching records found</th>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default Table;
